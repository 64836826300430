<template>
  <div class="mb-4">
    <v-row class="justify-space-between ma-2">
      <h2>{{ $t('keysSign') }}</h2>
      <v-btn
          @click="viewForm = !viewForm"
          color="primary"
          dark
      >
        <span v-show="!viewForm">
          <v-icon>mdi-plus</v-icon> {{ $t('addNewKey') }}
        </span>
        <span v-show="viewForm">
          <v-icon>mdi-close</v-icon> {{ $t('addNewKey') }}
        </span>
      </v-btn>
    </v-row>
    <v-slide-x-reverse-transition>
      <v-card v-if="viewForm" outlined class="d-flex flex-column col-12">
        <v-tabs v-show="!signData" v-model="tab">
          <v-tab>{{ $t('addNewKey') }}</v-tab>
          <v-tab>{{ $t('addStamp') }}</v-tab>
        </v-tabs>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ tab === 0 ? $t('addNewKey') : $t('addStamp') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!signData">
            <v-autocomplete
                v-model="server"
                :items="serverList"
                :label="$t('acsk')"
                :no-data-text="$t('noData')"
                item-text="name"
                item-value="address"
                class="pt-0 mt-2"
            />
            <v-file-input
                v-model="signKey"
                :label="$t('signKey')"
                accept=".ZS2,.zs2,.JKS,.jks,.DAT,.dat"
            />
            <v-autocomplete
              v-if="tab === 1"
              v-model="port"
              @blur="$v.port.$touch()"
              :items="listPorts"
              :label="$t('port')"
              :no-data-text="$t('noData')"
              :item-text="labelName"
              :error-messages="portErrors"
              class="pt-0 mt-2"
              validate-on-blur
              return-object
            />
            <v-text-field
                v-model="signK.password"
                :label="$t('password')"
                type="password"
                @keyup.enter="checkFieldsValidation"
            />
          </v-container>
          <v-container v-else-if="signData">
            <v-col>
              <span class="font-weight-bold">{{ $t('typeKey') }}</span>
              {{ $t(signK.type_signature === 'SI' ? 'signEDS' : 'stampEDS') }}
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t('name') }}</span>
              {{ signData.subjFullName }}
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t('position') }}</span>
              {{ signData.subjTitle }}
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t('email') }}</span>
              {{ signData.subjEMail }}
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t('city') }}</span>
              {{ signData.subjLocality }}
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t('company') }}</span>
              {{ signData.subjOrg }}
            </v-col>
            <v-col v-if="tab === 1">
              <span class="font-weight-bold">{{ $t('port') }}</span>
              {{ port[labelName] }}
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!signData"
            @click="checkFieldsValidation"
            :loading="isLoading.read"
            color="primary darken-1"
            text
          >
            {{ $t('readKey') }}
          </v-btn>
          <v-btn
            v-if="signData"
            @click="cancel"
            color="primary darken-1"
            text
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="signData"
            @click="saveKey"
            :disabled="disabledAddKey"
            :loading="isLoading.save"
            color="primary darken-1"
            text
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-x-reverse-transition>
    <v-container class="d-flex flex-wrap ma-0 pa-0 mt-3">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(key, index) in keys"
          :key="index"
        >
          <v-expansion-panel-header class="d-flex flex-start">
            <div>
              <b class="mr-1">{{ key.type_signature === 'SI' ? $t('name') : $t('port') }}:</b>
              <span v-if="key.type_signature === 'SI'">{{ key.name }}</span>
              <span v-else>{{ key.port ? getDirectoryObject({ id: key.port, value: 'ports' })[labelName] : '' }}</span>
              ({{ key.type_signature === 'SI' ? $t('actualSignEDS') : $t('actualStampEDS') }})
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-left">
            <div class="mt-2">
              <b>{{ $t('edrpou') }}:</b>
              {{ key.EDRPOU }}
            </div>
            <div class="mt-2">
              <b>{{ $t('createDate') }}:</b>
              {{ key.created_at }}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import { getDirectoryObject } from '@/mixins/main'
import { requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'AddSignature',
  props: {
    report: Object
  },
  data () {
    return {
      tab: 0,
      port: null,
      eu: null,
      server: 'uakey.com.ua',
      signKey: null,
      viewForm: false,
      signData: null,
      baseURL: process.env.VUE_APP_API,
      signK: {
        password: null,
        type_signature: 'SI',
        EDRPOU: null,
        name: null
      },
      disabledAddKey: false,
      isLoading: {
        read: false,
        save: false
      },
      getDirectoryObject
    }
  },
  computed: {
    ...mapState({
      serverList: state => state.eds.servers,
      keys: state => state.eds.keys,
      listPorts: state => state.directory.ports.filter(port => !port.is_disable),
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['serverByAddress']),
    portErrors () {
      const errors = []
      if (!this.$v.port.$dirty) return errors
      if (!this.$v.port.required) errors.push(this.$i18n.t('emptyField'))
      return errors
    }
  },
  watch: {
    server (value) {
      let server = this.serverByAddress(value)

      let settings = {
        address: server.ocspAccessPointAddress,
        beforeStore: true,
        port: server.ocspAccessPointPort,
        useOCSP: true
      }

      this.eu.signLib.SetOCSPSettings(settings)
      // ocspAccessPointAddress
      // ocspAccessPointPort
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'addEDS', access: checkAccess('addEDS') })
    this.$store.dispatch('getKeys')
    this.$store.dispatch('getServers')
  },
  mounted () {
    // eslint-disable-next-line
    this.eu = new DigitalSign()
    setTimeout(this.eu.init.bind(this.eu), 100)

    // this.signLib.SetOCSPSettings(settings)
  },
  validations () {
    return {
      port: {
        required: requiredIf(function () {
          return this.tab === 1
        })
      }
    }
  },
  methods: {
    ...mapActions(['getServers', 'setKey']),

    checkFieldsValidation () {
      if (this.$v.$invalid) this.$v.$touch()
      else this.readKey()
    },

    readKey () {
      this.isLoading.read = true
      let _onError = (e) => {
        this.$notification.error(e.message)
        this.isLoading.read = false
      }

      try {
        let _onSuccess = (keyName, key) => {
          this.eu.loadAndApprovePrivateKey(key, this.signK.password, this.server, (data) => {
            this.signData = data
            this.signK.EDRPOU = this.signData.subjEDRPOUCode
            this.signK.name = this.signData.subjCN
            let cert = this.eu.signLib.GetCertificate(this.signData.issuer, this.signData.serial, null)
            let getCertificateInfo = this.eu.signLib.ParseCertificate(cert)

            if (getCertificateInfo.extKeyUsages === 'Цифрова печатка') this.signK.type_signature = 'ST'
            else this.signK.type_signature = 'SI'
            let existSign = false

            this.keys.forEach(item => {
              if (item.type_signature === 'SI') existSign = true
            })

            if (this.signK.type_signature === 'ST' && !existSign) {
              this.disabledAddKey = true
              this.$notification.warning('onlySignKey')
            }

            this.isLoading.read = false
          })
        }

        let _onFileRead = (readedFile) => {
          _onSuccess(readedFile.file.name, readedFile.data)
        }

        // eslint-disable-next-line no-undef
        this.eu.signLib.ReadFile(this.signKey, _onFileRead.bind(this), _onError.bind(this))
      } catch (e) {
        this.$notification.error(e)
      }
    },
    saveKey () {
      this.isLoading.save = true
      let dataSign = new FormData()
      dataSign.append('password', this.signK.password)
      dataSign.append('type_signature', this.signK.type_signature)
      dataSign.append('EDRPOU', this.signK.EDRPOU)
      dataSign.append('name', this.signK.name)
      dataSign.append('file_signature', this.signKey)
      if (this.tab === 1) dataSign.append('port', this.port.id)
      this.setKey(dataSign).then(response => {
        if ([200, 201].includes(response.code)) {
          this.$notification.success('keyAdded')
          this.viewForm = false
          this.isLoading.save = false
        } else if (response.code === 400) {
          if (response.data[0] === 'First add email to your account') this.$notification.warning('addEmailFirst')
        }
      })
    },
    cancel () {
      this.signK = {
        password: null,
        type_signature: 'SI',
        EDRPOU: null,
        name: null
      }
      this.signData = false
    }
  }
}
</script>
